import React, { useState } from 'react'
import Logo from '../assets/logo.png'
import './Header.css'
import bars from '../assets/bar.png'
import {Link} from 'react-scroll'

const Header = () => {
  const mobile = window.innerWidth<=768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <div className="header">
        <img src={Logo} alt="" className='logo'/>
        {(menuOpened === false && mobile===true) ? (
          <div onClick={() => setMenuOpened(true)}>
            <img src={bars} alt="" style={{width:'1.5rem',height:'1.5rem',marginTop:'1rem'}}/>
          </div>
        ): (
        <ul className='header-menu'>
            <li onClick={() => setMenuOpened(false)}>
              <Link to='home'
              activeClass='active'
              onClick={() => setMenuOpened(false)}
              span={true} 
              smooth={true}>
                Home</Link>
            </li>

            <li onClick={() => setMenuOpened(false)}>
              <Link to='programs'
                onClick={() => setMenuOpened(false)}
                span={true} 
                smooth={true}>
                  Programs</Link>
            </li>

            <li onClick={() => setMenuOpened(false)}>
              <Link to='reasons'
                  onClick={() => setMenuOpened(false)}
                  span={true} 
                  smooth={true}>
                    Why Us</Link>
            </li>

            <li onClick={() => setMenuOpened(false)}>
              <Link to='search-exercise'
                    onClick={() => setMenuOpened(false)}
                    span={true} 
                    smooth={true}>
                Excercise</Link>
            </li>
            <li onClick={() => setMenuOpened(false)}>
              <Link to='plans-container'
                onClick={() => setMenuOpened(false)}
                span={true} 
                smooth={true}>
                  Plans</Link>
            </li>

            <li onClick={() => setMenuOpened(false)}>
              <Link to='Testinomial' 
              onClick={() => setMenuOpened(false)}
              span={true} 
              smooth={true}>Testimonials</Link></li>
            <li onClick={() => setMenuOpened(false)}>(610) 888-1111</li>
        </ul>
      )}
    </div>
  )
}

export default Header