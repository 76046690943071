export const programsData = [
    {
        image:"lift.png",
        heading: "Strength Training",
        details: "In this program, you are trained to improve your strength through many exercises."
    },
    {
        image:"run.png",
        heading: "Cardio Training",
        details: "In this program, you are trained to do sequential moves in range of 20 until 30 minutes."
    },
    {
        image:"burn.png",
        heading: "Fat Burning",
        details: "This program is suitable for you who wants to get rid of your fat and lose their weight."
    },
    {
        image:"heart.png",
        heading: "Health Fitness",
        details: "This program is designed for those who exercises only for their body fitness not body building."
    },
];
