import image1 from '../../assets/women.jpg'
import image2 from '../../assets/women1.jpg'
import image3 from '../../assets/women2.jpg'

export const TestinomialData = [
    {
        image:image1,
        review:"I made a right choice by choosing Lady Fitness Club and by choosing the right plan and program I already achieved my ideal body!",
        name: "BILLY ROSSI",
        status: "ENTREPRENEUR"
    },
    {
        image:image2,
        review:"I made a right choice by choosing Lady Fitness Club and by choosing the right plan and program I already achieved my ideal body!",
        name: "SIA SANTORO",
        status: "CUSTOMER"
    },
    {
        image:image3,
        review:"I have trained many peoples who are unable to do exercise and unwilling to choose right program but I made them fit and fine and help to choose right thing!",
        name: "ELINA ABADI",
        status: "COACH"
    },
];
