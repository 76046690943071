import React from 'react'
import './Reasons.css'
import img1 from '../assets/image1.png'
import img2 from '../assets/image2.png'
import img3 from '../assets/image3.png'
import img4 from '../assets/image4.jpg'
import tick from '../assets/tick.jpeg'
import ad from '../assets/adidas.png'
import nike from '../assets/nike.png'
import bata from '../assets/bata.png'

const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-r">
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
      </div>
      <div className="right-r">
        <span>Some Reasons</span>
        <div>
          <span className='stroke-text-g'>Why</span>
          <span>Choose Us?</span>
        </div>

        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>OVER 140+ EXPERT COACHES</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>TRAIN SMARTER AND FASTER THAN BE</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>1 FREE PROGRAM FOR NEW MEMBER</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>RELIABLE PARTNERS</span>
          </div>
        </div>
        <span 
        style={{
          color:"rgb(254, 4, 204)",
          fontWeight: "normal"
        }}>
          OUR PARTNERS
        </span>
        <div className="partners">
          <img src={ad} alt="" />
          <img src={nike} alt="" />
          <img src={bata}alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons
