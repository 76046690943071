import React from 'react'
import './SearchExercise.css'
import { ExerciseData } from './data/ExerciseData'


const SearchExercise = () => {
  return (
    
    <div className="search-exercise">
        {/*Header*/}
        <div className="search-header">
            <span className='stroke-text-p'>Search</span>
            <span>Exercise</span>
            <span className='stroke-text-p'>to shape you</span>
        </div>

        <div className="search-bar">
          <input type="text" placeholder='Search Exercise' />
          <button className='btn'>Search</button>
        </div>

        <div className="exercise-category">
        <div className="blur exercise-blur-1"></div>
         <div className="blur exercise-blur-2"></div>
        {ExerciseData.map((exercise)=>(
                <div className="exercise">
                    <img src={require(`../components/data/${exercise.image}`)} alt="Program Image"/>
                    <span>{exercise.heading}</span>
                </div>
            ))}
        </div>

        <div className="learn-exercise">
            <div className="learn-heading">
                <span className='stroke-text-p'>Explore</span>
                <span>Exercise</span>
            </div>

            <div className="learn">
                <div className="left-learn">
                Engaging in gym exercises offers a comprehensive approach to physical fitness, encompassing strength training, cardiovascular workouts, flexibility exercises, and more. Incorporating a variety of exercises into your gym routine can help target different muscle groups, improve overall fitness, and enhance endurance and stamina. Strength training exercises such as squats, deadlifts, and bench presses help build muscle mass, increase strength, and improve bone density. 
                Additionally, flexibility exercises such as yoga or stretching routines promote joint mobility, prevent injuries, and enhance overall flexibility. A well-rounded gym exercise program not only contributes to physical health but also boosts mood, reduces stress, and increases energy levels, leading to a healthier and more balanced lifestyle.
                <span>You can find in this video.....</span>
                </div>

                <div className="right-learn">
                    <iframe
                        width="560"
                        height="315"
                        src="https://youtu.be/agvdkRc_img?si=aJpamVTFtqOc_GKy"
                        title="Exercise Steps"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </div>
      </div>
  )
}

export default SearchExercise
