import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
   <div className="footer" id="footer">
        <div className="blur-w blur-f-1"></div>
    <div className="blur-w blur-f-2"></div>
    <div className="footer-container">
        <p>© 2024 Lady Fitness Club. All rights reserved.</p>
        <nav>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">Program</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
    </div>
   </div>
  )
}

export default Footer
