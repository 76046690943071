export const ExerciseData = [
    {
        image:"pro.png",
        heading: "All",
    },
    {
        image:"back.png",
        heading: "Back",
    },
    {
        image:"cardio.png",
        heading: "Cardio",
    },
    {
        image:"chest.jpeg",
        heading: "Chest",
    },
    {
        image:"waist.png",
        heading: "Waist",
    },
    {
        image:"upper_arms.png",
        heading: "Arms",
    },
    {
        image:"run.png",
        heading: "Legs",
    },
    {
        image:"back.jpeg",
        heading: "Shoulders",
    },
];
