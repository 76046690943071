export const PlansData = [
    {
        image:"heart.png",
        name:"BASIC PLAN",
        price:"25",
        features:[
            "2 hours of exercises",
            "Free consultation of Coaches",
            "Access to the Community",
        ],
    },
    {
        image:"premium.png",
        name:"PREMIUM PLAN",
        price:"30",
        features:[
            "5 hours of exercises",
            "Free consultation of Coaches",
            "Access to minibar",
        ],
    },
    {
        image:"pro.png",
        name:"PRO PLAN",
        price:"45",
        features:[
            "8 hours of exercises",
            "Consultation of Private Coach",
            "Free Fitness Merchandises",
        ],
    },
];
