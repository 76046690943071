import React from 'react'
import {PlansData} from'../components/data/PlansData'
import './Plans.css'
import whiteTick from '../components/data/tick.png'

const Plans = () => {
  return (
    <div className="plans-container">
        <div className="blur plan-blur-1"></div>
        <div className="blur plan-blur-2"></div>
        <div className="programs-header">
            <span className='stroke-text-p'>Join Your </span>
            <span>Journey</span>
            <span className='stroke-text-p'>With Us</span>
        </div>

        {/*plans card*/}
        <div className="plans">
        {PlansData.map((plans,i)=>(
                <div className="plan" key={i}>
                    <img src={require(`../components/data/${plans.image}`)} alt="Program Image"/>
                    <span>{plans.name}</span>
                    <span>$ {plans.price}</span>

                    <div className="features">
                        {plans.features.map((feature,i)=>(
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>
                    
                    <div>
                        <span>See more benefits</span>
                        <button className="btn" style={{color:"white",backgroundColor:"black",width:"15rem",marginTop:"1rem"}}>Join now</button>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Plans
