import React from 'react'
import './Program.css'
import { programsData } from '../components/data/programsData'
import RightArrow from '../assets/rightArrow.png'

const Programs = () => {
  return (
    <div className="Programs" id='programs'>

        {/*Header*/}
        <div className="programs-header">
            <span className='stroke-text-p'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text-p'>to shape you</span>
        </div>

        {/*program card*/}
        <div className="program-categories">
            {programsData.map((program)=>(
                <div className="category">
                    <img src={require(`../components/data/${program.image}`)} alt="Program Image"/>
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                    <div className="join-now"><span>Join Now</span><img src={RightArrow} style={{width:"2rem"}} /></div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Programs
