import React, { useState } from 'react';
import './Testinomial.css';
import { TestinomialData } from './data/TestimonialData';
import LeftArrow from '../assets/leftArrow.png'
import RightArrow from '../assets/rightArrow.png'
import {motion} from 'framer-motion'

const Testinomial = () => {
  const transition = {type:'spring',duration:3}
  const [selected, setSelected] = useState(0);
  const tLength = TestinomialData.length;

  return (
    <div className="Testinomial">
      <div className="left-t">
        <span style={{color:"rgb(254, 4, 204)"}}>Testimonials</span>
        <span className='stroke-text-g'>What they</span>
        <span>say about us</span>
        <motion.span
          key={selected}
          initial={{ opacity: 0, x: -100 }}
          animate={{opacity: 1, x: 0}}
          exit={{ opacity: 0, x: 100}}
          transition={transition}
          >{TestinomialData[selected].review}
        </motion.span>
        <span>
          <span style={{color:"rgb(254, 4, 204)"}}>
            {TestinomialData[selected].name}
          </span>{" "}
          - {TestinomialData[selected].status}
        </span>
      </div>

      <div className="right-t">
        <motion.div
            initial={{ opacity: 0,x :-100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x:0}}
        ></motion.div>
        <motion.div
            initial={{ opacity: 0,x :100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x:0}}
        ></motion.div>
        <motion.img 
        key={selected}
        initial={{ opacity: 0, x: 100 }}
        animate={{opacity: 1, x: 0}}
        exit={{ opacity: 0, x: -100}}
        transition={transition}
        src={TestinomialData[selected].image} alt="" />
        <div className="arrows">
          <img 
          onClick={()=>{
            selected===0
            ? setSelected(tLength-1)
            : setSelected((prev) => prev - 1);
          }}
          src={LeftArrow} alt="" />
          <img onClick={()=>{
            selected===tLength-1
            ? setSelected(0)
            : setSelected((prev) => prev + 1);
          }}
          src={RightArrow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Testinomial;
