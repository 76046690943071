import './App.css';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Join from './components/Join';
import Plans from './components/Plans';
import Programs from './components/Programs'; 
import Reasons from './components/Reasons';
import SearchExercise from './components/SearchExercise';
import Testinomial from './components/Testinomial';

function App() {
  return (
    <div className="App">
      <Hero/> 
      <Programs/>
      <Reasons/>
      <SearchExercise/>
      <Plans/>
      <Testinomial/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;
